<template>
    <div>
        <el-button type="primary" size="small" @click="selectAttenBtn">取消关注</el-button>

        <el-table
        :data="tableData"
        size="small"
        style="width: 100%;margin-top: 20px;"
        @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            align="center"
            width="50">
            </el-table-column>
            <el-table-column
                prop="name"
                label="文件名"
                width="400">
            </el-table-column>
            <el-table-column
                prop="type"
                label="文件类型"
                align="center">
            </el-table-column>
            <el-table-column
                prop="username"
                label="所有者"
                align="center">
            </el-table-column>
            <el-table-column
                prop="size"
                label="大小"
                align="center">
            </el-table-column>
            <el-table-column
                prop="time"
                label="修改日期"
                align="center">
            </el-table-column>
            <el-table-column
            width="100"
            align="center">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-star-off" size="small" title="收藏"></el-button>
                    <el-button type="text" icon="el-icon-view" size="small" title="查看"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
        background
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formLine.pageNum"
        :page-size="formLine.pageSize"
        layout="prev, pager, next" 
        :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data(){
        return{
            tableData: [{
                name: '全国公共资源交易平台 (陕西省.榆林市) (2).pdf',
                type: 'pdf',
                username: '可可可爱爱可爱',
                size: '215KB',
                time: '2022-008 13:45'
            },{
                name: '全国公共资源交易平台 (陕西省.榆林市) (2).pdf',
                type: 'pdf',
                username: '可可可爱爱可爱',
                size: '215KB',
                time: '2022-008 13:45'
            }],
            formLine: {
                pageNum: 1,
                pageSize: 10
            },
            total: 1,
            multipleSelection: []
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 取消关注
        selectAttenBtn(){
            if(this.multipleSelection.length == 0) {
                this.$message.error('请选择数据');
                return;
            } else if(this.multipleSelection.length > 1) {
                this.$message.error('只能选择一条数据');
                return;
            }
            this.$message.success('请求接口');
        },
        // 分页
        handleCurrentChange(num) {

        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-table {
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
/deep/ .pagebox {
    margin-top: 20px;
}
</style>